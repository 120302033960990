.room-filter {
  padding: 3rem 5rem;

  h1 {
    font-size: 2.5rem;
    margin: 2rem;
    text-align: center;
    font-family: "Inika";
    text-transform: uppercase;
    letter-spacing: 3px;
    margin-bottom: 1rem;
  }
}

.filter-choice {
  display: flex;
  margin-bottom: 1.5rem;
  align-items: center;
  justify-content: center;
  position: relative;
  left: 0;
  transform: translateX(-2%);
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  letter-spacing: 3px;
  font-family: "Lato";

  button {
    background-color: #fef5eb;
    border-radius: 5rem;
    margin-left: 2rem;
    outline: 1px solid aliceblue;
    border: 1px solid var(--hover-color);
    color: var(--hover-color);
    padding: 0.5rem 1.2rem;
    transition: 0.5s all;
  }
  button:hover {
    background-color: var(--hover-color);
    border: 2px solid aliceblue;
    color: aliceblue;
    transition: 0.5s all;
    transform: scale(1.1);
    outline-offset: -0.3rem;
  }
  button.clicked {
    outline-offset: -0.3rem;
    background-color: var(--hover-color);
    color: aliceblue;
    border: 2px solid aliceblue;
    transition: 0.5s all;
    transform: scale(1.1);
  }
}
.room-cards {
  margin-left: 2rem;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  gap: 2rem;
  flex-wrap: wrap;
}

.roomcard-img {
  width: 100%;
  height: 35vh;
}

.singleroom-card {
  width: 30%;
  height: 60%;
  position: relative;
  border-radius: 0.6rem;
  box-shadow: 2px 4px 8px 5px #c5c5c7;
  cursor: pointer;
  transition: 0.5s all;
}
.singleroom-card:hover {
  transform: scale(1.2);
  box-shadow: 2px 4px 12px 1px #f5a898;
  transition: 0.5s all;

  .overlay {
    height: 100%;
  }
}

.loading-spinner {
  background-color: transparent;
  margin-top: 5rem;
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading {
  color: #ff8800;
  animation: spin 1s linear infinite;
  font-size: 3rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.price-div {
  background-color: rgb(251, 250, 243);
  box-shadow: 0px 2px 2px 2px #f6dfc4;
  padding: 0.7rem 1.2rem;
  position: absolute;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  right: 0.2rem;
  top: 50%;
  border-radius: 4rem;

  .room-price2 {
    text-decoration: line-through;
    color: gray;
  }
  .room-price {
    font-family: "Lato";
    color: var(--primary-color);
    letter-spacing: 1px;
    font-weight: 200;
    font-size: 0.9rem;
  }
}

.room-rating {
  margin-left: 1rem;
  font-size: 13px;
  color: aliceblue;
  background-color: #fa9d33;
  padding: 0.3rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  .star-icons {
    margin-right: 0.3rem;
  }
}

.overlay {
  height: 0%;
  width: 100%;
  opacity: 0.85;
  background: linear-gradient(transparent, rgb(151, 150, 150) 100%);
  border-radius: 10x;
  position: absolute;
  left: 0;
  bottom: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px;
  text-align: center;
  transition: height 0.7s;

  h2 {
    font-weight: 600;
    font-size: 1rem;
    border-radius: 1rem;
    font-family: "Lato";
    color: aliceblue;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: var(--primary-color);
    padding: 0.7rem 1.2rem;
  }
  h2:hover {
    transform: scale(0.95);
    transition: 0.5s all;
    background-color: var(--hover-color);
    opacity: 1;
  }
}

.overlay2 {
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    font-weight: 400;
    font-size: 0.9rem;
    border-radius: 5px;
    border: none;
    font-family: "Lato";
    margin: 1rem 0rem;
    color: aliceblue;
    letter-spacing: 2px;
    background-color: #8686f0;
    padding: 0.6rem 1rem;
  }

  button:hover {
    transform: scale(0.95);
    transition: 0.5s all;
    background-color: #7272f7;
  }
}

@media (max-width: 770px) {
  .filter-choice {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1.5rem;
    left: 0;
    padding: 0.5rem 1rem;

    button {
      margin-left: 1rem;
      padding: 0.5rem 1.2rem;
      margin-bottom: 0.5rem;
    }
  }
  .room-filter {
    padding: 2rem;
  }
  .singleroom-card {
    width: 45%;
    margin: auto;
  }
  .room-cards {
    margin: auto;
  }
}
@media (max-width: 500px) {
  .room-filter {
    h1 {
      font-size: 1.7rem;
      margin-top: 2.5rem;
    }
  }
  .singleroom-card {
    width: 100%;
    margin: auto;
    padding: 0;
  }

  .room-cards {
    margin: 0rem;
    padding: 0;
  }
}

/* Single room reserve  */

.room-reserve {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
  padding-top: 5rem;
  background-color: #f3eeea;
}

.left-part {
  flex: 4;
}

.room-reserve-img-div {
  display: flex;
  flex-direction: column;
}

.main-img {
  width: 100%;
  height: 60vh;
  border-radius: 8px;
}

.small-imgs-div {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.small-imgs-div img {
  width: 32.2%;
  height: 22vh;
  border-radius: 8px;
}

.room-reserve-desc {
  margin-top: 1.5rem;
  font-family: "Lato";

  h2 {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 2px;
  }

  .price-div-2 {
    background-color: rgb(251, 250, 243);
    box-shadow: 0px 2px 2px 2px #f6dfc4;
    padding: 0.5rem 1.2rem;
    position: relative;
    display: flex;
    right: 0;
    align-items: center;
    justify-content: center;
    border-radius: 4rem;
    margin-bottom: 1rem;

    .room-price-2 {
      font-family: "Lato";
      letter-spacing: 1px;
      font-weight: 200;
      font-size: 0.9rem;
    }
    .room-price-22 {
      color: var(--primary-color);
      font-family: "Lato";
      letter-spacing: 1px;
      text-decoration: line-through;
      font-weight: 200; margin-right: .5rem;
      font-size: 0.9rem;
    }
  }
}

.right {
  flex: 2;
  background-color: #fff;
  padding: 1rem;
  height: 70%;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.user-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.user-form-title h3 {
  font-family: "Lato";
  font-size: 2rem;
  margin: 1.2rem 0rem;
  letter-spacing: 3px;
  text-align: center;
}

.user-form form {
  margin-top: -1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.user-form form div {
  display: flex;
  flex-direction: column;
}

.user-form form label {
  font-family: Lato, sans-serif;
  font-size: 0.8rem;
}

.user-form form input {
  outline: none;
  padding: 0.8rem 1.2rem;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 4px;
  font-family: "Lato";
  font-size: 1rem;
}
.user-form form input:focus {
  outline: none;
}

.user-form form button {
  padding: 0.5rem 1.2rem;
  border-radius: 6px;
  margin-top: 1rem;
  background-color: #ffa769;
  color: white;
  font-family: "lato";
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.5s;
}

.user-form form button:hover {
  background-color: var(--hover-color);
  transform: scale(0.95);
  transition: all 0.5s;
}

@media (max-width: 770px) {
  .small-imgs-div {
    display: none;
  }
  .main-img {
    height: 30vh;
    width: 80%;
    width: auto;
    padding: 0;
    margin: 0;
  }
  .room-reserve-desc {
    .price-div-2 {
      margin-top: 0.5rem;
    }
  }
}
