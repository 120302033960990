.mainContainer {
    width: 100%;
}

#polygon {
    padding-left: 20px;
    margin-left: 20px;
    width: 100%;
}

.wave {
    display: flex;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 300px;
    position: relative;
    margin-bottom: 2rem;
    background-color: #f1dbcb;
    clip-path: polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%);
    shape-outside: polygon(0% 0%, 0% 82.5%, 1.69492% 84.3293%, 3.38983% 86.1385%, 5.08475% 87.9078%, 6.77966% 89.6179%, 8.47458% 91.25%, 10.1695% 92.7862%, 11.8644% 94.2098%, 13.5593% 95.505%, 15.2542% 96.6578%, 16.9492% 97.6555%, 18.6441% 98.487%, 20.339% 99.1435%, 22.0339% 99.6176%, 23.7288% 99.9041%, 25.4237% 100%, 27.1186% 99.9041%, 28.8136% 99.6176%, 30.5085% 99.1435%, 32.2034% 98.487%, 33.8983% 97.6555%, 35.5932% 96.6578%, 37.2881% 95.505%, 38.9831% 94.2098%, 40.678% 92.7862%, 42.3729% 91.25%, 44.0678% 89.6179%, 45.7627% 87.9078%, 47.4576% 86.1385%, 49.1525% 84.3293%, 50.8475% 82.5%, 52.5424% 80.6708%, 54.2373% 78.8616%, 55.9322% 77.0922%, 57.6271% 75.3821%, 59.322% 73.75%, 61.017% 72.2138%, 62.7119% 70.7902%, 64.4068% 69.495%, 66.1017% 68.3422%, 67.7966% 67.3446%, 69.4915% 66.513%, 71.1864% 65.8565%, 72.8814% 65.3824%, 74.5763% 65.0959%, 76.2712% 65%, 77.9661% 65.0959%, 79.661% 65.3824%, 81.3559% 65.8565%, 83.0509% 66.513%, 84.7458% 67.3446%, 86.4407% 68.3422%, 88.1356% 69.495%, 89.8305% 70.7902%, 91.5254% 72.2138%, 93.2203% 73.75%, 94.9153% 75.3821%, 96.6102% 77.0922%, 98.3051% 78.8616%, 100% 80.6708%, 100% 0%);

    h1{
        font-size: 4rem;
        color: black;
        position: relative;
        left: 50%;
        transform: translate(-50%);
        font-family: "Inika";
        letter-spacing: 5px;
        font-weight: 400;
    }
}



/* About Description */

.about-desc{
    height: 100%;
    width: 100%;
}
.inside-about-div{
    margin: 2rem 3rem;
    height: 70%;
}

.about-container{
    position: relative;
    display: flex;
    width: 100%;
    gap: 3rem;
    padding: 3rem;
}

.about-text{
    margin: 1rem 1rem 2rem 2rem;
    width: 50%;
    position: relative;

    
    p{
        font-size: 1.1rem;
        letter-spacing: 1px;
        font-family: "Lato";
        line-height: 2.5rem;
    }
}

.about-title::before {
    content: "";
    width: .3rem;
    height: 3.5rem;
    background-color: var(--hover-color);
    position: absolute;
    border-radius: 1rem;
    top: .3rem;
    left: -2rem;
}

.about-title {
    height: 4rem;
    color: var(--primary-color);
    margin-left: 2rem;
    font-size: 2.4rem;
    letter-spacing: 3px;
    font-family: "Inika";
    margin-bottom: 2rem;
    text-transform: uppercase;
    position: relative;
}

.about-img{
    position: relative;
    bottom: 2rem;
    right: -2rem;
    background-color:#f2f3f5;
    width: 40%;
    height: 65vh;
    padding:2rem;
    border-radius: 50%;
    box-shadow: 0px 4px 16px 2px var(--hover-color);

    img{
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}

@media(max-width:768px){
    .inside-about-div{
        margin: 0rem 0rem;
        padding: 0rem;
    }
    .about-container{
        flex-direction: column;


        .about-text{
            width: 100%;
            text-align: justify;
            margin: 0rem;

            .about-title{
                font-size: 1.8rem;
                margin-bottom: 0;
            }
                        .about-title::before {
                            content: "";
                            width: .3rem;
                            height: 2rem;
                            background-color: #eab38c;
                            position: absolute;
                            border-radius: 1rem;
                            top: .3rem;
                            left: -2rem;
                        }

            p{
                font-size: .9rem;
                    font-family: "Lato";
                    line-height: 1.5rem;
            }
        }
    }

        .about-img {
            background-color: #f2f3f5;
            width:90%;
            height: 350px;
            margin-top: 1rem;
            padding: 1rem;
            border-radius: .3rem;
            box-shadow: 0px 4px 16px 2px #eab38c;
    
            img {
                width:100%;
                height: 100%;
                border-radius: .3rem;
            }
        }

}
@media(max-width:480px){
    .about-img {
        right: 0;
            background-color: #f2f3f5;
            width: 100%;
            height: 200px;
            margin-top: 1rem;
            padding: 1rem;
            border-radius: .3rem;
            box-shadow: 0px 4px 16px 2px #eab38c;
    
            img {
                width: 100%;
                height: 100%;
                border-radius: .3rem;
            }
        }
}
/* About choose */

.about-choose{
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.about-choose2{
    height: 100%;
    width: 100%;
    display: flex;
}

.contact-container{
    background-color: #f2f3f5;
    width: 50%;
    height: 100%;
    position: relative;
    padding: 2rem;

    .contact-title{
        text-align: center;
        font-size: 2rem;
        position: relative;
        letter-spacing: 4px;
        text-transform: uppercase;
        font-family: "Lato";
    }
    .contact-line{
        position: relative;
        left: 20%;
        width: 60%;
        margin-top: .8rem;
        border: .2px solid black;
    }
}

.contact-icons{
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    margin: 2rem 0rem 2rem 4rem;
}

.contact-hours {
    text-align: center;
    font-family: "Lato";
    h2{
        margin: 2rem 0rem 1rem 2rem;
        font-size: 2rem;
        letter-spacing: 4px;
    }
    h3{
        margin-top: 1rem;
        font-size: 1.2rem;
        letter-spacing: 2px;
    }
}

.about--bg{
    height: 180vh;
    width: 100vw;
    object-fit: cover;
}

.custom--about{
    height:200%;
    width: 100vw;
    object-fit: contain;
}
.abt-content{
    height: 200%;
    width: 100vw;
}



.contact-container-form{
    background-color: #f2f3f5;
    text-align: center;
    font-family: "Lato";
        width: 50%;
        height: 100%;
        position: relative;
        padding: 3rem 4rem 0 4rem;

}

.contact-container-form h3 {
    font-size: 2rem;
    letter-spacing: 4px;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.contact--form {
    max-width: 50rem;
    margin: auto;
}

.contact--form form {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

form input,
textarea {
    font-family: "Lato";
    font-size: 1rem;
    max-width: 50rem;
    padding: .7rem 1.8rem;
    background-color: transparent;
    transition: 2s all;
    border-radius: .3rem;
    border: 1.5px solid black;
    margin-top: 1rem;
}

form .contact--btn {
    border-radius: 15px;
    width: 30%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color:var(--primary-color);
    color: white;
    padding: .7rem 1.7rem;
    border: none;
    border-radius: 3px;
    font-size: .8rem;
    cursor: pointer;
    transition: all .3s;
}

form .contact--input:focus,
textarea:focus {
    outline: none;
}

form .contact--btn:hover {
    background-color: var(--hover-color);
    transition: .3s all;
    transform: scale(1.1);
}




/* services css  */
/* services css  */
.service {
    height: 100%;
    margin: 3rem 3rem 3rem 3rem;
    overflow: hidden;
    position: relative;
    padding: 1rem;
    width: 100%;
}

.service-img {
    height: 35vh;
    width: 100%;
    border-radius: .3rem;
}

.service-card {
    padding: 1rem;
    width: 30%;
    position: relative;
    border-radius: .6rem;
    box-shadow: 4px 8px 12px 10px #c7c7da;
    cursor: pointer;
    flex-shrink: 0;
}

.service-allcards {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 2rem;
    transition: transform 0.5s ease-out;
}
.service-card-desc{
    line-height: 2rem;
}

.service-card-button{
    background-color: var(--primary-color);
        transition: all .3s;
        border-radius: 5px;
        
    }
.service-card-button:hover{
    background-color: var(--hover-color);
    transition: all .3s;
    transform: scale(1.1);
}
@media(max-width:768px) {
    .contact-container {
        width: 100%;

        .contact-title {
            font-size: 1.8rem;
            letter-spacing: 2px;
        }
    }

    .contact-line {
        position: relative;
        left: 20%;
        width: 60%;
        margin-top: .5rem;
        border: .2px solid black;
    }

    .contact-icons {
        display: flex;
        justify-content: space-between;
        font-size: 1.5rem;
        margin: 2rem 0rem 2rem 1rem;

        p {
            font-size: .8rem;
        }
    }

    .contact-hours {
        text-align: center;
        font-family: "Lato";

        h2 {
            margin: 2rem 0rem 1rem 2rem;
            font-size: 1.5rem;
            letter-spacing: 2px;
        }

        h3 {
            margin-top: .8rem;
            font-size: 1rem;
        }

    }

    .contact-container-form {
        width: 100%;
        background-color: #f8f8f8;
    }

    .service{
        margin: 1rem;
    }

    .service-card{
        min-width: 90%;
        height: auto;
        display: flex;
        margin: 0;

        .service-img{
            height: 200px;
            max-width:30%;
            min-width: 150px;
        }

    }
    .service-card-desc{
        line-height: 1.4rem;
    }
        form .contact--btn{
            width: 100%;
        }
}

@media(max-width:480px){
    .contact-container {
            width: 100%;
    
            .contact-title {
                font-size: 1.4rem;
                letter-spacing: 2px;
            }
        }
    
        .contact-line {
            position: relative;
            left: 20%;
            width: 50%;
            margin-top: .5rem;
            border: .2px solid black;
        }
    
        .contact-icons {
            display: flex;
            justify-content: space-between;
            font-size: 1.5rem;
            margin: 2rem 0rem 2rem 0rem;
    
            p {
                font-size: .6rem;
            }
        }
    
        .contact-hours {
            text-align: center;
            font-family: "Lato";
    
            h2 {
                margin: 2rem 0rem 1rem 0rem;
                font-size: 1.2rem;
                letter-spacing: 2px;
            }
    
            h3 {
                margin-top: .8rem;
                font-size: .8rem;
            }
    
        }
    
        .contact-container-form {
            width: 100%;
            background-color: #f8f8f8;
        }
    
        .service {
            margin: 1rem;
        }
    
        .service-card {
            min-width: 90%;
            height: auto;
            display: flex;
            margin: 0;
    
            .service-img {
                height: 130px;
                min-width: 150px;
            }
    
            .service-card-desc {
                display: none;
            }
        }
                form .contact--btn {
                    width: 100%;
                }
                .responsive-service{
                    display: flex;
                    flex-direction: column;
                }
                .service-card-button{
                    margin-bottom: -1rem;
                }
                .service-name{
                    font-size: .8rem;
                }
            }