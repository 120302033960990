.menu-home{
    height: 80vh;
    width: 100%;
    object-fit: cover;
    position: relative;
}

.menu-h1{
    font-size: 140px;
    letter-spacing: 2rem;
    font-weight: 100;
    position: absolute;
    color: aliceblue;
    top: 20%;
    left: 50%;
    transform: translate(-50%,20%);
}

.special-menu-container{
    margin: 1rem 2rem;
    display:flex;
    flex-wrap: wrap;
    gap: 2rem;
    flex-wrap: wrap;
}

.menucard-img{
    width: 100%;
    height: 30vh;
    border-radius: .5rem;
}
.special-card{
            width: 23%;
            height: 30%;
            position: relative;
            border-radius: .6rem;
            box-shadow: 2px 4px 8px 5px #c5c5c7;
            cursor: pointer;
            transition: .5s all;
            padding: .5rem;

}
.special-h1{
    text-align: center;
    font-size: 3rem;
    font-weight: 400;
    letter-spacing: 5px;
    margin: 1rem 0rem;
}


/* menuform */
.menu-choose2 {
    height: 80%;
    margin: 2rem 0rem;
    width: 100%;
    display: flex;
    background-image: url("https://plus.unsplash.com/premium_photo-1718285552243-85861ac9e179?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxfHx8ZW58MHx8fHx8");
}

.menu-container-form {
    background-color: #f2f3f5;
    text-align: center;
    font-family: "Lato";
    width: 50%;
    height: 100%;
    position: relative;
    padding: 3rem 4rem 0 4rem;

}

.menu-container-form h3 {
    font-size: 2rem;
    letter-spacing: 4px;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

.menu--form {
    max-width: 50rem;
    margin: auto;
}

.menu--form form {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

form input,
textarea {
    font-family: "Lato";
    font-size: 1rem;
    max-width: 50rem;
    padding: .7rem 1.8rem;
    background-color: transparent;
    transition: 2s all;
    border-radius: .3rem;
    border: 1.5px solid black;
    margin-top: 1rem;
}

form .contact--btn {
    border-radius: 15px;
    width: 30%;
    margin-top: 1rem;
    margin-bottom: 2rem;
    background-color: #ededf1;
    color: #0f4d55;
    padding: .7rem 1.7rem;
    border: 1px solid black;
    border-radius: 8px;
    font-size: .8rem;
    cursor: pointer;
    transition: all .3s;
}

form .menu--input:focus,
textarea:focus {
    outline: none;
}

form .menu--btn:hover {
    background-color: #c7c7da;
    transition: .3s all;
    transform: scale(1.1);
}

.menu--form .contact--btn:hover{
    color: white;
    border: 1px solid white;
}

.view-menu{
    display: flex;
    margin: 2rem;
    padding: 1rem;
    background-color: rgb(253, 235, 238);
    align-items: center;
    flex-direction: column;

    p{
        font-size: 1.3rem;
        
    }
    div{
        display: flex;
        gap: 3rem;

        h2{
            font-size: 1.2rem;
        }
    }
}

.no-special-div{
    margin: 1rem 3rem;
    background-color: #eeeef1;
    width: 100%;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: center;

    h1{
        font-size: 2rem;
        color: rgb(233, 133, 133);
    }
}

@media (max-width:780px){
    .menu-home{
        height: 60vh;
    }
    .menu-h1{
        font-size: 80px;
    }
    .special-card{
        width: 45%;
    }
        .no-special-div{
            margin: 1rem 0rem;
            width: 90%;
        }
}
@media (max-width:480px){
    .special-card{
        width: 90%;
        margin: auto;
    }
    .special-h1{
        text-align: center;
            font-size: 2rem;
            font-weight: 400;
            letter-spacing: 2px;
            margin: 1rem 0rem;
    }
        .menu-container-form{
            width: 100%;
        }
         .no-special-div {
             margin: 1rem 0rem;
             width: 100%;
         }
}

