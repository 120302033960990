.res-desc {
    height: 90%;
    width: 100%;
    background-color: #F0E5CB;
    display: flex;
    flex-direction: column;
    padding: 4rem;
    padding-bottom: 1rem;
    margin-bottom: 3rem;
}

.res-text {
    height: 30%;
    margin-left: 2rem;
    margin-top: 1rem;
    width: 100%;
}

.res-text h1 {
    font-size: 2.5rem;
    letter-spacing: 1.5px;
    font-weight: 700;
    font-family: "Inika";
}

.res-text p {
    width: 60%;
    font-size: 1rem;
    line-height: 2rem;
    margin-top: 1rem;
}

.res-text .res-text-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.res-text .res-button {
    margin-right: 4rem;
    background-color: var(--primary-color);
    padding: 12px 25px;
    border-radius: 6px;
    font-size: 1rem;
    color: white;
    letter-spacing: 1.2px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
    transition: 0.3s all;
}
.res-text .res-button:hover {
    background-color: var(--hover-color);
    transform: scale(1.03);
    transition: 0.3s all;
}

.res-images {
    margin-top: 2rem;
    margin-left: 2rem;
    width: 100%;
    height: 100%;
    display: flex;
}

.side-room {
    height: 44%;
    width: 100%;
    object-fit: cover;
    border-radius: 0.5rem;
    margin-top: 1rem;
    box-shadow: 4px 8px 16px 5px #d1b26a;
}

.side-room2 {
    height: 44%;
    object-fit: cover;
    width: 100%;
    margin-top: 1rem;
    border-radius: 0.5rem;
    box-shadow: 4px 8px 16px 5px #d1b26a;
}

.main-room {
    height: 90%;
    object-fit: cover;
    width: 98%;
    border-radius: 0.5rem;
    box-shadow: 4px 8px 16px 5px #d1b26a;
}

@media (max-width: 770px) {
    .res-text-div{
        flex-direction: column;
    }
    .res-desc {
        padding:1rem;
        margin-bottom: 2rem;
        margin: 0;
    }

    .res-text {
        margin: 0;
        align-items: center;
        text-align: center;
    }

    .res-text h1 {
        font-size: 2rem;
    }

    .res-text p {
        width: 90%;
        font-size: 0.9rem;
        line-height: 1.5rem;
        text-align: justify;
    }

    .res-text .res-button {
        margin: 1rem 0;
        padding: 10px 20px;
        font-size: 0.9rem;
    }

    .res-images {
        margin: 1rem 0;
        width: 170%;
        margin-bottom: -3rem;
    }

    .side-room,
    .side-room2 {
        display: none;
    }

    .main-room {
        height: 80%;
        width: 100%;
    }
}

@media (max-width: 480px) {
    .res-desc {
        padding: 1.5rem 0.5rem;
        margin-bottom: 1.5rem;
    }

    .res-text h1 {
        font-size: 1.8rem;
    }

    .res-text p {
        font-size: 0.8rem;
        line-height: 1.3rem;
    }

    .res-text .res-button {
        padding: 8px 16px;
        font-size: 0.8rem;
    }
}