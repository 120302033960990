/* .menu-desc {
    background-color: #D4E0ED;
    display: flex;
    height: 100vh;
    width: 100%;
}

.images-div {
    display: flex;
    width: 45%;
    margin: 8rem 0 6rem 8rem;
}

.menu-text {
    width: 50%;
    margin: 0 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.menu-text h1 {
    font-family: "Charmonman";
    font-weight: 600;
    font-size: 2.1rem;
    margin-bottom: 1rem;
}

.menu-text p {
    width: 90%;
    font-size: 1.2rem;
    line-height: 3rem;
    text-align: center;
    font-weight: 400;
}

.main-food-div {
    margin-right: 1rem;
    height: 100%;
}

.two-foods {
    display: flex;
    flex-direction: column;
}

.mainfood-img {
    object-fit: cover;
    width: 500px;
    height: 100%;
    border-radius: 0.5rem;
    box-shadow: 4px 8px 16px 5px #85a9d3;
}

.secondfood-img {
    object-fit: cover;
    width: 350px;
    height: 65%;
    border-radius: 0.3rem;
    margin-bottom: 1rem;
    box-shadow: 4px 8px 16px 5px #85a9d3;
}

.thirdfood-img {
    object-fit: cover;
    width: 350px;
    border-radius: 0.3rem;
    height: 35%;
    box-shadow: 4px 8px 16px 5px #85a9d3;
}

.see-menu {
    font-size: 1rem;
    padding: 12px 28px;
    letter-spacing: 0.5px;
    border: 1px solid black;
    margin-top: 1rem;
    background-color: transparent;
    box-shadow: 0px 1px 1px rgba(154, 177, 239, 0.3);
    border-radius: 4px;
    transition: 0.5s all linear;
}

.see-menu:hover {
    background-color: aliceblue;
    cursor: pointer;
    transform: scale(1.05);
    transition: 0.3s all linear;
}

@media (max-width: 768px) {
    .menu-desc {
        flex-direction: column;
        align-items: center;
        height: auto;
    }

    .images-div {
        width: 90%;
        margin: 2rem 0;
        align-items: center;
    }

    .menu-text {
        width: 90%;
        margin: 1rem 0;
        text-align: center;
    }

    .menu-text h1 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
    }

    .menu-text p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
    .images-div-menu-food{
        width: 100%;
    }
    .main-food-div,two-foods{
        width: 50%;
    }
    .mainfood-img,
    .secondfood-img {
        width: 100%;
        height: 200px;
        box-shadow: 2px 4px 8px 2px #85a9d3;
    }

    .thirdfood-img {
        display: none;
    }
} */


.maps-div{
    height: 100%;
    width: 100%;
    
}

.con--location {
    margin: 2rem;
    font-size:2.2rem;
    font-weight: 400;
    letter-spacing: 3px;
    font-family: "Inika";
    color: var(--hover-color);

}
.map{
    font-size: 2rem;
    color: var(--hover-color);
}
.map-frame{
    height: 90vh;
    width: 100%;
}

@media (max-width:480px){
    .con--location{
        font-size: 1.5rem;
        margin: 1rem;
        letter-spacing: 1px;
    }
    .map{
        font-size: 1.2rem;
    }
    .map-frame{
        height: 60vh;
    }
}